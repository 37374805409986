.App {
  text-align: center;
  background-color: #fff;
  min-height: 100vh;
}

.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.header-logo {
  height: 70px;
  padding-right: 50px;
  object-fit: contain;
}

.header-instagram {
  font-size: 24px;
  color: #ea8717;
  text-decoration: none;
}

.header-instagram:hover {
  color: #000;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 0;
}

.video-background {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 100%;
  height: auto;
  min-height: 100%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  z-index: -1;
}

.contact-map-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
}

.contact-section {
  flex: 1;
  padding: 20px;
}

.google-maps {
  flex: 1;
  padding: 20px;
}

.map-container {
  height: 500px;
  width: 100%;
  border-radius: 20px;
}

@media (max-width: 768px) {
  .contact-map-container {
    flex-direction: column;
  }

  .contact-section,
  .google-maps {
    width: 100%;
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .contact-map-container {
    flex-direction: column;
  }

  .contact-section,
  .google-maps {
    width: 100%;
    padding: 10px;
  }
}

hr {
  border: none;
  height: 2px;
  background-color: #f4f4f4;
  margin: 20px 0;
}

.full-screen-header {
  width: 100vw;
  height: 100vh;
  background-image: url("./padel3.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  position: relative;
  z-index: 1;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
}

.vimeo-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1; /* Send it behind content */
  object-fit: cover;
  pointer-events: none; /* Ensure no interaction with the video */
}

.header-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.3);
  z-index: 1;
  letter-spacing: 0.1em;
  text-indent: 0.3em;
  font-weight: 800;
  line-height: 1.6em;
}

.header-content .header-title {
  text-align: center;
  width: calc(100% - 320px);
  color: #ea8717;
  text-shadow: none;
  font-size: 1.1em;
  text-align: center;
  letter-spacing: 0.1em;
  text-indent: 0.3em;
  font-weight: 800;
  line-height: 1.6em;
}

.header-content h1 {
  font-size: 5rem;
  margin: 0;
  line-height: 1.2;
}

.header-content p {
  font-size: 1.5rem;
  margin-top: 10px;
}

.header-content span {
  color: #ea8717;
}

.scroll-down {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 2rem;
  color: #ea8717;
  cursor: pointer;
  animation: bounce 2s infinite;
}

.content-section {
  padding: 50px;
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
  text-align: center;
  border: none;
}

.content-section p {
  width: 100%;
}

.content-section h2 {
  font-size: 1.1em;
  margin-bottom: 20px;
  color: #10121ab3;
  text-align: center;
  letter-spacing: 0.1em;
  text-indent: 0.3em;
  text-transform: uppercase;
  font-weight: 800;
  line-height: 1.6em;
}

.content-section h2 span {
  color: #ea8717;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(-50%) translateY(0);
  }
  40% {
    transform: translateX(-50%) translateY(-10px);
  }
  60% {
    transform: translateX(-50%) translateY(-5px);
  }
}

@media (max-width: 768px) {
  .header-content h1 {
    font-size: 3rem;
  }

  .header-content p {
    font-size: 1.2rem;
  }

  .header-content {
    max-width: 90%;
    padding: 15px;
  }
}

@media (max-width: 390px) {
  .video-background {
    top: 80%;
  }
}

@media (max-width: 414px) {
  .video-background {
    top: 70%;
  }
}

@media (max-width: 820px) {
  .video-background {
    top: 60%;
  }
}

@media (max-width: 1024px) {
  .video-background {
    top: 50%;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .header-content h1 {
    font-size: 2.5rem;
  }

  .header-content p {
    font-size: 1rem;
  }

  .header-content {
    max-width: 95%;
    padding: 10px;
  }

  .scroll-down {
    font-size: 1.5rem;
  }
}

.section-container h1,
.content-section .underline-gap {
  position: relative;
  display: inline-block;
  font-size: 3rem;
  color: #ea8717;
  text-align: center;
  letter-spacing: 0.1em;
  text-indent: 0.3em;
  text-transform: uppercase;
  font-weight: 800;
  line-height: 1.6em;
}

.section-container .underline-gap,
.content-section .underline-gap {
  color: #10121ab3;
}

.section-container .underline-gap::after,
.content-section .underline-gap::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  height: 4px;
  width: 100%;
  background-color: #ea8717;
}

.content-section-slide {
  padding: 50px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  position: relative;
}

.content-text {
  opacity: 0;
  transition: all 0.8s ease-in-out;
  transform: translateX(100px);
}

.slide-in-left {
  opacity: 1;
  transform: translateX(0);
}

.slide-in-right {
  opacity: 1;
  transform: translateX(0);
}

footer {
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #ea8717;
  color: #fff;
}

footer p {
  margin: 0;
  font-weight: 600;
}

footer a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
}

footer svg {
  font-size: 24px;
  margin-left: 10px;
  color: #fff;
}

footer svg:hover,
footer a:hover {
  color: #000;
}

.text-center {
  text-align: center;
}

.business-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 20px;
}

.business-section p {
  font-size: 1.1em;
  margin-bottom: 20px;
  color: #10121ab3;
  text-align: center;
  letter-spacing: 0.1em;
  text-indent: 0.2em;
  font-weight: 500;
  line-height: 1.6em;
}

.business-section p em {
  font-size: 0.9rem;
  letter-spacing: 0.1em;
  font-weight: 700;
}

@media (min-width: 768px) {
  .business-section {
    padding: 50px 100px;
  }
}

@media (min-width: 1024px) {
  .business-section {
    padding: 50px 200px;
  }

  .business-section p {
    font-size: 1.2em;
  }
}
