.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px;
  padding: 20px;
  justify-items: center;
  align-items: center;
}

.gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.1s ease;
}

.gallery-item:hover {
  transform: scale(1.05);
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.1s ease;
}

.gallery-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.1s ease;
  border-radius: 10px;
}

.gallery-item:hover::after {
  opacity: 1;
}

.gallery-item:hover img {
  opacity: 0.7;
}
